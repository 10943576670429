<template>
  <b-sidebar
    id="add-new-booking-sidebar"
    :visible="isEventHandlerSidebarUpdateActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @hidden="$emit('hidden');"
    @change="(val) => $emit('one')"
  >
    <template>
<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Booking #{{bookingData.member_package_id}}-{{bookingData.booking_id}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="$emit('hidden')"
        />

      </div>



       <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
        <label class="section-label mb-1">Booking Details</label>



<!-- readonly input -->
      <b-form-group
        label="Client Name"
        label-for="ClientName"
      >
        <b-form-input
          id="ClientName"
          v-model="bookingData.client_name"
          readonly
        />
      </b-form-group>
        <b-form-group
        label="PackageName"
        label-for="PackageName"
      >
        <b-form-input
          id="PackageName"
          v-model="bookingData.package_name"
          readonly
        />
      </b-form-group>



 <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules=""
            >

              <b-form-group
                label="Start Date"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="newBookingData.start"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <!--
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules=""
            >

              <b-form-group
                label="End Date"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="newBookingData.end"
                  class="form-control"
                  disabled
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          -->
          <b-form-group>
             <b-form-timepicker v-model="newBookingData.start_time" inline hour24 minutes-step="15"/>
           </b-form-group>

            <validation-provider
            #default="validationContext"
            name="room"
            rules="required"
          >
            <b-form-group
              label="Room"
              label-for="room"
              :state="getValidationState(validationContext)"
            >
              <v-select
                 v-model="newBookingData.room_id"
                :options="roomOptions"
                :clearable="true"
                :filterable="false"
              >
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label="Instructor"
              label-for="Instructor"
              :state="getValidationState(validationContext)"
            >
              <v-select
                 v-model="newBookingData.user_id"
                 :reduce="option => option.id"
                :options="staffOptions"
                :clearable="false"
                :filterable="false"
              >
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Remarks"
            label-for="remarks"
          >
          <b-form-input
            id="remarks"
            v-model="newBookingData.remarks"
            trim
          />
          </b-form-group>
 <!-- Form Actions -->
          <div class="d-flex mt-2">
                      </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              block
              type="button"
              @click="submit_now('')"
              v-if="(bookingData.signature_created_at === null && $can('read', 'staff')) || current_user_id == bookingData.user_id"
            >
              Update
            </b-button>
         <b-button
              class="mt-2"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              block
              v-if="bookingData.signature_created_at === null"
              variant="outline-secondary"
              @click="$emit('hidden')"
            >
              Back
            </b-button>
            <b-button
              class="mt-1"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              block
              variant="outline-success"
              @click="attend()"
              v-if="bookingData.is_attend != 'Y'&& ($can('read', 'staff') || current_user_id == bookingData.user_id)"
            >
              Signed In
            </b-button>
            <b-button
              class="mt-1"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              block
              variant="outline-success"
              @click="undo_attend()"
              v-if="bookingData.is_attend == 'Y' && $can('read', 'admin')"
            >
              Unsign
            </b-button>
          <div>
          <b-button
            v-ripple.400="'#c7a528'"
            type="button"
            class="mt-1"
            block
            variant="warning"
            v-if="(bookingData.signature_created_at === null && $can('read', 'staff')) || current_user_id == bookingData.user_id"
            @click="go_to_signature(bookingData.id)"
          >
            Signature
          </b-button>
          <b-button
            v-ripple.400="'#c7a528'"
            type="button"
            class="mt-1"
            block
            variant="danger"
            @click="delete_booking(bookingData.id)"
            v-if=" $can('read', 'admin') || ( bookingData.is_attend != 'Y' && current_user_id == bookingData.user_id )"
          >
            Delete
          </b-button>
          <img v-if="bookingData.signature_created_at !== null" :src="bookingData.signature" style="width:100%" class="mt-1">
        </div>
        <br><br><br><br><br><br><br><br>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal,BFormTimepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    BFormTimepicker,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal, VBModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  watch: {
    bookingData : function(newValue,oldValue) {
      this.newBookingData.user_id = newValue.user_id
      this.newBookingData.booking_id = newValue.booking_id
      this.newBookingData.end = newValue.end_at
      this.newBookingData.user_package_id = newValue.user_package_id
      this.newBookingData.id = newValue.id
      this.newBookingData.start = newValue.start_at
      this.newBookingData.member_package_id = newValue.member_package_id
      this.newBookingData.start_time = newValue.start_time
      this.newBookingData.room_id.label = newValue.room_name
      this.newBookingData.room_id.id = newValue.room_id
      this.newBookingData.remarks = newValue.remarks
    }
  },
  model: {
    prop: 'isEventHandlerSidebarUpdateActive',
    event: 'update:is-add-new-Member-sidebar-active'
  },
  props: {
    isEventHandlerSidebarUpdateActive:{
      type: Boolean,
      require: true,
    },
    bookingData:{
      type: Object,
      require: true,
    }
  },
  data() {
    return {
      newBookingData: {
        start:null,
        end:null,
        user_id:null,
        remarks:null,
        booking_id:null,
        room_id:{
          label:null,
          id:null
        }
      },
      current_user_id : JSON.parse(localStorage.getItem('userData')).id,
      staffOptions: [],
      memberOptions : [],
      roomOptions : [],
      member_id: null,
      memberPackageOptions : [],
      required,
      end_at: null,
      paymentMethodOptions: [
        { label: 'Cash', value: 'cash' },
        { label: 'Visa', value: 'visa' },
      ],
      lock_quantity : true,
      alphaNum,
      email,
      countries,
      booking:{
        member_id:null
      },
      blankBookingData: {
        room_id:null,
        member_package_id:null,
        member_id:{
          labal:'',
          value:''
        },
        start:null,
        end:null
      },

    }
  },
  created() {
    console.log(this.current_user_id)
    this.get_rooms()
    this.get_staff()
  },
  methods: {
    undo_attend() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+this.newBookingData.booking_id+"/undo_attend/", this.newBookingData)
          .then(res => {
            if (res.data.result === true) {
              this.$emit('hidden')
              this.booking = this.blankBookingData
              this.$refs.simpleRules.reset()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              })
            }
          })
    },
    get_staff() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/staffs/?perpage=9999&page=1`)
        .then(res => {
          this.staffOptions = res.data.staffs
        })
    },
    go_to_signature(id) {
      console.log(id)
      console.log(this.bookingData)
      this.$router.push('/pt-detail/'+this.newBookingData.booking_id)
    },
    change_end() {
      this.$emit("change_end",this.end_at)
    },
    get_rooms() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/rooms/?sh`)
        .then(res => {
          this.roomOptions = res.data.rooms
        })
    },
    fetchOptions (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/search/member/?string='+search)
            .then(res => {
              this.memberOptions = res.data
            })
            }, 500);
      },
      fetchOptions_package (search, loading) {
        console.log(this.booking.member_id)
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/member/'+this.booking.member_id.value+'/packages/select/')
            .then(res => {
              this.memberPackageOptions = res.data.packages
            })
            }, 500);
      },
      delete_booking() {
        this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+this.newBookingData.booking_id+"/delete/", this.newBookingData)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('hidden')
                this.booking = this.blankBookingData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
      },
      attend() {
        this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+this.newBookingData.booking_id+"/attend/", this.newBookingData)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('hidden')
                this.booking = this.blankBookingData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
      },
    submit_now() {
          this.$http.post(process.env.VUE_APP_API_BASE+'/booking/'+this.newBookingData.booking_id+"/", this.newBookingData)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('hidden')
                this.booking = this.blankBookingData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
    }
  },
  setup(props, { emit }) {
    const blankPackageData = {
      expire_at:'',
      quantity:'',
      package: {
        expiry_figure:'1',
        expiry_unit:'DAY'
      }
    }

    const packageData = ref(JSON.parse(JSON.stringify(blankPackageData)))
    const resetuserData = () => {
      packageData.value = JSON.parse(JSON.stringify(blankPackageData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      packageData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
