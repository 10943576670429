<template>
<div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
        <!-- Sidebar -->
        <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column" :class="{'show': isCalendarOverlaySidebarActive}">
            <calendar-sidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" @fetch="test"/>
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                    <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar"/>
                </div>
            </div>
        </div>

        <!-- Sidebar Overlay -->
        <div class="body-content-overlay" :class="{'show': isCalendarOverlaySidebarActive}" @click="isCalendarOverlaySidebarActive = false"/>

        <calendar-event-handler
            v-model="isEventHandlerSidebarActive"
            :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
            :room-options="rooms"
            :booking-data="bookingData"
            @hidden="isEventHandlerSidebarActive=false;get_booking('')"/>
        <calendar-event-handler-update
            v-model="isEventHandlerSidebarUpdateActive"
            :is-event-handler-sidebar-active-update.sync="isEventHandlerSidebarUpdateActive"
            :room-options="rooms"
            :booking-data="bookingData"
            @hidden="isEventHandlerSidebarUpdateActive=false;get_booking('')"
            @change_end="change_end_now"/>

    </div>
</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import CalendarEventHandlerUpdate from './calendar-event-handler/CalendarEventHandlerUpdate.vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'


export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    CalendarEventHandlerUpdate,
  },
  data() {
    return {
      isCalendarOverlaySidebarActive:false,
      isEventHandlerSidebarUpdateActive:false,
      selectedRooms:[],
      rooms:[],
      bookingData:{
        end_at:null,
        start_at:null,
        room_id:null
      },
      isEventHandlerSidebarActive:false,
      calendarOptions: {

        eventSources: [
        { url: process.env.VUE_APP_API_BASE+`/center/`+this.$store.getters['center/currentCenterId']+`/booking_v2/?token=`+JSON.parse(localStorage.getItem('userData')).token }
        ],
        eventDidMount: function(info) {
          if ( JSON.parse(localStorage.getItem('userData')).config.booking_attend_color !== null ) {
            if (info.event._def.extendedProps.is_attend == "Y") {
              info.el.style = 'background-color:#'+ JSON.parse(localStorage.getItem('userData')).config.booking_attend_color +' !important'
            }
          }
     
        },
        slotEventOverlap : false,
        lazyFetching:false,
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        views: {
          timeGridWeek: { // name of view
            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }
          },
          timeGrid: {
            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }
          },
          week: {
          titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }
          },
          day: {
            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }
          }
        },
        firstDay: JSON.parse(localStorage.getItem('userData')).config.calendar_first_day,
        allDaySlot : false,
        scrollTime :  JSON.parse(localStorage.getItem('userData')).config.slot_min_time,
        slotMinTime:  JSON.parse(localStorage.getItem('userData')).config.slot_min_time,
        slotMaxTime:  JSON.parse(localStorage.getItem('userData')).config.slot_max_time,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'listWeek',
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        customButtons: {
          sidebarToggle: {
            // --- This dummy text actual icon rendering is handled using SCSS ----- //
            text: 'Add',
            click: (event)=>{
               this.isEventHandlerSidebarActive = true
            },
          },
    },
        events:[],
        eventClick:(event)=>{
           this.showEvent(event)
        },
        
        isEventHandlerSidebarActive:false
      }
    }
  },
  created() {
   // console.clear()
  //  this.get_booking('')
  },
  methods : {
    change_end_now(val) {
    //  console.log(val)
      this.bookingData.end_at = val
    },
    test(value) {
      this.get_booking(value.join(','))
    },
     get_booking(room_array) {
    //    console.log("load")
        let calendar = this.$refs.refCalendar.getApi()
        calendar.refetchEvents()


    },
    showEvent(data) {
      this.bookingData = data.event._def.extendedProps
    //  console.log(this.bookingData)
      this.isEventHandlerSidebarUpdateActive = true
     // console.log(this.isEventHandlerSidebarActive)
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
